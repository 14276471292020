import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import './index.css'
import Footer from '../components/Footer'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query HeadingQuery {
        site {
          siteMetadata {
            title
            description
            keywords
          }
        }
      }
    `}
    render={(data) => (
      <div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            {
              name: 'description',
              content: data.site.siteMetadata.description,
            },
            { name: 'keywords', content: data.site.siteMetadata.keywords },
          ]}
        />
        <Header />
        {children}
        <Footer>
          Backgrounds made in Cinema 4D, iOS app in Swift, site in React.{' '}
          <a href="mailto:support@designcode.io">Email us</a> to ask anything. ©
          2018
        </Footer>
      </div>
    )}
  />
)

export default Layout
